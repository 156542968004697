import React from 'react'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import moment from 'moment'

import { LoadingMask } from '@elo-kit/components/loading-mask/LoadingMask'
import { PurchaseMethods } from 'shared/components/purchase-methods/PurchaseMethods'

import { LW_FORBIDDEN_COUNTRIES } from 'constants/countries.constants'
import {
  PAYMENT_FORMS,
  PAYMENT_PROVIDERS,
  STRIPE_SOFORT_ALLOWED_COUNTRIES,
} from 'constants/paymentSettingShared.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import { moveToTheEndOfArray, moveToTheBeginningOfArray } from 'utils/helpersShared.utils'
import { isEmpty } from 'utils/validatorsShared.utils'

import { Nullable } from 'types/helpers'
import { useShopStores } from 'shop/hooks/use-store'
import { useNextRouter } from 'shop/hooks/use-next-js-router'

import { KLARNA_KEY } from 'constants/options.constants'
import { TermsAndBuyButton } from './TermsAndBuyButton'

interface SearchParams {
  is_preview: string
  cabinet_preview: string
}

interface AllowedPaymentMethods {
  pmList: string[]
  cardProvider: string
  sofortProvider: string
  payerCountry: string
  payLaterDisabled: boolean | string
  bankWireDisabled: boolean
  klarnaDisabled: boolean
}

interface Props {
  upsellItems?: Nullable<React.ReactElement>
  withoutTermsAndBuy?: boolean
  withWidgetLine?: boolean
  iframeFree?: boolean
  themeStore
}

const PREVIEW_PAYMENT_METHODS = [
  PAYMENT_FORMS.bankWire,
  PAYMENT_FORMS.sofort,
  PAYMENT_FORMS.paypal,
  PAYMENT_FORMS.payLater,
  PAYMENT_FORMS.card,
  PAYMENT_FORMS.sepa,
]

const getAllowedPaymentMethods = ({
  pmList,
  cardProvider,
  sofortProvider,
  payerCountry,
  payLaterDisabled,
  bankWireDisabled,
  klarnaDisabled,
}: AllowedPaymentMethods) => {
  let result = pmList
  const isLemonway = cardProvider === PAYMENT_PROVIDERS.lemonWay
  const isSofortStripe = sofortProvider === PAYMENT_PROVIDERS.stripe
  const isSofortElopageConnect = sofortProvider === PAYMENT_PROVIDERS.elopageConnect
  const isSofortLW = sofortProvider === PAYMENT_PROVIDERS.lemonWay

  if (isLemonway && LW_FORBIDDEN_COUNTRIES.includes(payerCountry)) {
    result = result.filter((name) => name !== PAYMENT_FORMS.card)
  }

  if (
    isSofortLW ||
    ((isSofortStripe || isSofortElopageConnect) && !STRIPE_SOFORT_ALLOWED_COUNTRIES.includes(payerCountry))
  ) {
    result = result.filter((name) => name !== PAYMENT_FORMS.sofort)
  }

  if (payLaterDisabled) {
    result = result.filter((name) => name !== PAYMENT_FORMS.payLater)
  }

  if (klarnaDisabled) {
    result = result.filter((name) => name !== PAYMENT_FORMS.klarna)
  }

  if (bankWireDisabled) {
    result = result.filter((name) => name !== PAYMENT_FORMS.bankWire)
  }

  return result
}

export const Purchase: React.FC<Props> = observer(function Purchase(props) {
  const { upsellItems = null, withoutTermsAndBuy = false, withWidgetLine = false, iframeFree, themeStore } = props
  const { paymentStore, sellerStore, trackingUserEventsStore, ordersStore, experimentsStore } = useShopStores()
  const {
    params: { is_preview: isPreview, cabinet_preview: cabinetPreview },
  } = useNextRouter<SearchParams>()

  const activePlan = paymentStore.store?.activePlan
  const payerCountry = paymentStore.payerForms?.userData?.payer?.country?.code

  const isExistAdditionalFee = (paymentStore.getAdditionalFee() || {}).id
  const isFreeWithFees = isEmpty(toJS(activePlan)) && isExistAdditionalFee
  const commonPaymentMethods = paymentStore.sortedEnabledMethods

  const additionalFeesPayMethods = isExistAdditionalFee
    ? [...paymentStore.getAdditionalFee().preferredPayMethods, ...paymentStore.getAdditionalFee().otherPayMethods]
    : []

  const sellerPaymentMethods = sellerStore.item.allowedPaymentMethods?.[sellerStore.item.allowedCurrencies?.[0]] || []

  let paymentMethods = commonPaymentMethods

  if (isExistAdditionalFee && !!isFreeWithFees) {
    paymentMethods = additionalFeesPayMethods.length ? additionalFeesPayMethods : sellerPaymentMethods
  }

  if (isExistAdditionalFee && !isFreeWithFees && !commonPaymentMethods.length) {
    paymentMethods = sellerPaymentMethods
  }

  const hasPaymentMethods = paymentMethods.length > 0

  const payLaterDisabled =
    Number(activePlan?.prefs?.testPeriod) > 0 ||
    activePlan?.prefs?.customStartDay ||
    paymentStore.store?.firstPaymentIsFree ||
    paymentStore.invoice?.token ||
    paymentStore.payerForms?.showGift ||
    activePlan?.prefs?.absoluteDates ||
    !!isFreeWithFees

  const klarnaDisabled =
    !!(Number(activePlan?.prefs?.testPeriod) > 0 || activePlan?.prefs?.customStartDay) ||
    !sellerStore.isAppActive(KLARNA_KEY)

  const bankWireDisabled = activePlan?.prefs?.absoluteDates

  const pmList = isPreview && !hasPaymentMethods ? PREVIEW_PAYMENT_METHODS : paymentMethods

  const sortPayMethods = () => {
    const paymentMethods = moveToTheEndOfArray(
      getAllowedPaymentMethods({
        pmList,
        cardProvider: sellerStore.item.cardProvider,
        payerCountry,
        sofortProvider: sellerStore.item.sofortProvider,
        payLaterDisabled,
        bankWireDisabled,
        klarnaDisabled,
      }),
      activePlan?.preferredPayMethods?.length ? '' : PAYMENT_FORMS.payLater
    )

    const paymentsOrderExperiment = experimentsStore.useExperiment('show_new_payment_methods_order')

    if (paymentsOrderExperiment.get('isNewPaymentsOrder', true)) {
      const userBrowserConfig = experimentsStore.useDynamicConfig('user_browser')
      const userCountryConfig = experimentsStore.useDynamicConfig('user_country')

      const userBrowserPaymentMethod = userBrowserConfig?.value['browser_payment_method']
      const userCountryPaymentMethod = userCountryConfig?.value['country_payment_method']
      const isPreferredPayMethod = activePlan?.preferredPayMethods?.length

      const allowToMoveCountryMethod = userCountryPaymentMethod && paymentMethods.includes(userCountryPaymentMethod)
      const allowToMoveBrowserMethod = userBrowserPaymentMethod && paymentMethods.includes(userBrowserPaymentMethod)

      if (allowToMoveCountryMethod) moveToTheBeginningOfArray(userCountryPaymentMethod, paymentMethods)
      if (allowToMoveBrowserMethod) moveToTheBeginningOfArray(userBrowserPaymentMethod, paymentMethods)
      if (isPreferredPayMethod && (userBrowserPaymentMethod || userCountryPaymentMethod))
        moveToTheBeginningOfArray(activePlan.preferredPayMethods[0], paymentMethods)
    }

    return paymentMethods
  }

  const allowedPaymentMethods = sortPayMethods()

  const purchaseMethodProps = {
    firstPaymentIsFree: paymentStore.store?.firstPaymentIsFree,
    handleIntentReset: paymentStore.handleIntentReset,
    pastDue: activePlan?.prefs?.pastDue,
    paypalProvider: sellerStore.item.paypalProvider,
    setStripeCardValidity: paymentStore.setStripeCardValidity,
    shouldResetIntent: paymentStore.shouldResetIntent,
    stripePubKey: sellerStore.item.stripePubKey,
    elopageConnectPubKey: sellerStore.item.elopageConnectPubKey,
    updatePaymethodsData: paymentStore.updatePaymethodsData,
    setBuyBtnDisabling: paymentStore.setBuyBtnDisabling,
    setStripeP24: paymentStore.setStripeP24,
    setStripeIdeal: paymentStore.setStripeIdeal,
    setStripeElements: paymentStore.setStripeElements,
    setStripeClient: paymentStore.setStripeClient,
    stripeCardValid: paymentStore.stripeCardValid,
    setStripeCard: paymentStore.setStripeCard,
    checkDigitalMethodsAvailability: paymentStore.checkDigitalMethodsAvailability,
    stripeDigitalPaymentMethods: paymentStore.stripeDigitalPaymentMethods,
    providers: {
      cardProvider: sellerStore.item.cardProvider,
      sofortProvider: sellerStore.item.sofortProvider,
      sepaProvider: sellerStore.item.sepaProvider,
      applePayProvider: sellerStore.item.applePayProvider,
      googlePayProvider: sellerStore.item.googlePayProvider,
      p24Provider: sellerStore.item.p24Provider,
      idealProvider: sellerStore.item.idealProvider,
      klarnaProvider: sellerStore.item.klarnaProvider,
    },
  }

  const isAllUpsellsFree = !!paymentStore.store.upsell?.length && paymentStore.store.upsell.every((item) => item.free)
  const secondRateIsNotFree = paymentStore.store?.firstPaymentIsFree && paymentStore.store?.withNextPayment
  const productIsFree = (iframeFree || paymentStore.store?.free) && !secondRateIsNotFree
  const showPurchaseMethods = !productIsFree && allowedPaymentMethods?.length > 0

  const today = moment().format(DATE_FORMATS.DDMMYYYY)
  const hideWarningMessage = activePlan?.firstIntervalDate && today < activePlan.firstIntervalDate && isAllUpsellsFree

  return (
    <div className='purchase'>
      {(paymentStore.isPurchaseLoading || paymentStore.stripeAuthentificationLoading) && <LoadingMask />}
      {showPurchaseMethods && <div className='container-title'>{I18n.t('react.shop.payment.form.payment')}</div>}

      {!paymentStore.buildedOrderLoading && secondRateIsNotFree && !hideWarningMessage && (
        <div role='alert' className='alert alert-warning alert-dismissible'>
          <button className='close' aria-label='Close' data-dismiss='alert' type='button'>
            <span aria-hidden='true'>×</span>
          </button>
          {I18n.t('react.shop.payment.form.enter_payment_details')}
        </div>
      )}

      {showPurchaseMethods && (
        <PurchaseMethods
          activeMethod={paymentStore.store?.paymethods?.form}
          allowedMethods={allowedPaymentMethods}
          forSubscription={paymentStore.store?.withNextPayment}
          forceDirty={paymentStore.store?.validateOnSubmit}
          sepaImmediateAccess={activePlan?.prefs?.sepaImmediate}
          pricingPlanSofortSepa={activePlan?.prefs?.sofortSepa}
          pricingPlanIdealSepa={activePlan?.prefs?.idealSepa}
          setStripeDigitalMethod={paymentStore.setStripeDigitalMethod}
          setFraudSessionIdentifier={paymentStore.setFraudSessionIdentifier}
          {...purchaseMethodProps}
          sellerStore={sellerStore}
          preferredPaymentMethods={activePlan?.preferredPayMethods}
          visualSeparationEnabled={activePlan?.visualSeparationEnabled}
          key={activePlan?.id}
          cabinetPreview={cabinetPreview}
          onMethodChange={({ activeMethod, allowedMethods }) => {
            trackingUserEventsStore.track({
              pageType: 'shop_checkout',
              eventType: 'payment_method_change',
              withExperiment: true,
              payload: {
                totalPaymentMethodCount: allowedMethods.length,
                selectedPaymentMethodType: activeMethod,
                selectedPaymentMethodPosition: allowedMethods.indexOf(activeMethod) + 1,
              },
            })
          }}
          themeStore={themeStore}
          ordersStore={ordersStore}
        />
      )}

      {withWidgetLine && showPurchaseMethods && <div className='widget-line' />}

      {!withoutTermsAndBuy && <TermsAndBuyButton upsellItems={upsellItems} />}
    </div>
  )
})
